<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row>
              <b-col cols="12">
                <!-- refId -->
                <b-form-group
                  :label="$t('Name')"
                  label-for="register-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="name"
                      name="register-name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <!-- refId -->
                <b-form-group
                  :label="$t('Detail')"
                  label-for="register-detail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Detail"
                    vid="detail"
                    rules="required"
                  >
                    <b-form-input
                      id="register-detail"
                      v-model="detail"
                      name="register-detail"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Detail')"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >{{ $t('Please input') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card
        no-body
        class="mb-2"
      >
        <div class="p-2">
          <h2>{{ $t('Notification to') }}</h2>
          <validation-observer ref="formDataEmails">
            <b-form>
              <div
                v-for="(item , index) in emails"
                :key="index"
              >
                <b-form-group
                  :label="$t('Email')"
                  :label-for="`notify${index}`"
                >
                  <b-form-input
                    :id="`notify${index}`"
                    v-model="emails[index]"
                    :name="`notify${index}`"
                    :placeholder="$t('Email')"
                  />
                </b-form-group>
              </div>
            </b-form>
          </validation-observer>
          <b-row
            align-v="center"
            class="justify-content-md-center"
          >
            <b-col md="auto">
              <b-button
                variant="primary"
                @click="newEmail()"
              >
                <feather-icon icon="PlusIcon" />
                {{ $t('Add Email') }}
              </b-button>
            </b-col>
          </b-row>
        </div>

      </b-card>
      <div
        v-for="(item , index) in questions"
        :key="index"
      >
        <b-card
          class="mb-2"
        >
          <div class="px-2">
            <b-row align-v="center">
              <b-form-checkbox
                v-model="item.show"
                class="custom-control-primary mr-ๅ"
                name="check-button"
                switch
              />
              <span>{{ item.show ? $t('Show') : $t('Hide') }}</span>
            </b-row>
          </div>

          <validation-observer :ref="`formData${index}`">
            <b-form class="p-2">
              <b-row>
                <b-col cols="9">
                  <!-- refId -->
                  <b-form-group
                    :label="$t('Question')"
                    :label-for="`question${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Question${index}`"
                      :vid="`question${index}`"
                      rules="required"
                    >
                      <b-form-input
                        :id="`question${index}`"
                        v-model="item.question"
                        :name="`question${index}`"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Name')"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('Please input') }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="3">
                  <!-- refId -->
                  <b-form-group
                    :label="$t('Choose a question type')"
                    label-for="register-Type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      vid="Type"
                      rules="required"
                    >
                      <v-select
                        id="register-Type"
                        v-model="item.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="OptionQuestion"
                        :reduce="(OptionQuestion) => OptionQuestion.value"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="item.type === 'many_options'"
                  cols="12"
                >
                  <hr>
                </b-col>
                <b-col
                  v-if="item.type === 'many_options'"
                  cols="12"
                >
                  <b-row
                    v-for="(list ,indexList) in item.listOption"
                    :key="indexList"
                    align-v="center"
                    :class="indexList !==0 ? 'mt-2' : ''"
                  >
                    <b-form-checkbox
                      :checked="false"
                      class="custom-control-primary mr-ๅ"
                      name="check-button"
                    />
                    <validation-provider
                      #default="{ errors }"
                      :name="`option${index}-${indexList}`"
                      :vid="`option${index}-${indexList}`"
                      rules="required"
                    >
                      <b-form-input
                        :id="`option${index}-${indexList}`"
                        v-model="list.option"
                        :name="`option${index}-${indexList}`"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Name')"
                      />
                    </validation-provider>
                    <b-button
                      v-if="item.listOption.length>1"
                      class="ml-2"
                      variant="outline-danger"
                      @click="deleteOption(index,indexList)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-button
                      v-if="(indexList+1) === item.listOption.length"
                      class="ml-2"
                      variant="outline-primary"
                      @click="addOption(index)"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-row>

                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
                <b-col cols="9" />
                <b-col cols="3">
                  <b-row align-v="center">
                    <b-button
                      class="mr-2"
                      variant="outline-primary"
                      @click="copyQuestion(item)"
                    >
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                    <b-button
                      v-if="questions.length>1"
                      class="mr-2"
                      variant="outline-danger"
                      @click="deleteQuestion(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                    <b-form-checkbox
                      v-model="item.required"
                      class="custom-control-primary mr-ๅ"
                      name="check-button"
                      switch
                    />
                    <span>{{ item.required ? $t('Required') : $t('Not required') }}</span>
                  </b-row>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-card>
      </div>

      <b-row
        align-v="center"
        class="justify-content-md-center"
      >
        <b-col md="auto">
          <b-button
            variant="primary"
            @click="newQuestion()"
          >
            <feather-icon icon="PlusIcon" />
            {{ $t('Add question') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- Action button -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="validationForm()"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import storeModule from '@/store/services/eCommerce/questionnaire'

const STORE_MODULE_NAME = 'questionnaire'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: '',
      detail: '',
      emails: [],
      questions: [
        // {
        //   question: '',
        //   type: 'short_message',
        //   listOption: [
        //     {
        //       option: '',
        //     },
        //   ],
        //   required: false,
        // },
      ],
      required,
    }
  },
  computed: {
    OptionQuestion() {
      return [
        { name: this.$t('Short message'), value: 'short_message' },
        { name: this.$t('Paragraph'), value: 'paragraph' },
        { name: this.$t('Many options'), value: 'many_options' },
        { name: this.$t('Link'), value: 'link' },
        { name: this.$t('Tel'), value: 'tel' },
        { name: this.$t('File'), value: 'file' },
        { name: this.$t('Image'), value: 'image' },
      ]
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    if (this.$route.params.id !== 'add') {
      const obj = {
        id: this.$route.params.id,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/view`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const respData = result.data.data
          this.selectedData(respData)
          // console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    } else {
      this.newEmail()
      this.newQuestion()
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    deleteEmail(index) {
      this.emails.splice(index, 1)
    },
    newEmail() {
      this.emails.push('')
    },
    addOption(index) {
      this.questions[index].listOption.push({
        option: '',
      })
    },
    deleteOption(index, indexList) {
      this.questions[index].listOption.splice(indexList, 1)
    },
    newQuestion() {
      this.questions.push(
        {
          question: '',
          type: 'short_message',
          show: true,
          listOption: [
            {
              option: '',
            },
          ],
          required: false,
        },
      )
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1)
    },
    copyQuestion(item) {
      this.questions.push({
        question: item.question,
        type: item.type,
        listOption: item.listOption,
        required: item.required,
        show: item.show,
      })
    },
    changeSwitch(event, index) {
      this.questions[index].required = event
    },
    changeShow(event, index) {
      this.questions[index].show = event
    },
    selectedData(data) {
      this.dataId = data._id
      this.name = data.name
      this.detail = data.detail
      this.questions = data.questions
      this.emails = data.emails
      if (data.emails.length <= 0) {
        this.newEmail()
      }
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to save')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'success',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$refs.formData.validate().then(async success => {
              if (success) {
                const listCheck = []
                for (let index = 0; index < this.questions.length; index += 1) {
                  // eslint-disable-next-line no-await-in-loop
                  const successPass = await this.$refs[`formData${index}`][0].validate().then(val => val)
                  listCheck.push(successPass)
                }
                if (listCheck.every(status => status === true)) {
                  const obj = {
                    id: this.dataId,
                    name: this.name,
                    detail: this.detail,
                    questions: this.questions,
                    emails: this.emails.filter(e => e !== null && e !== ''),
                  }
                  if (obj.id !== null) {
                    this.show = true

                    store
                      .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                      .then(result => {
                        console.log('fetch Success : ', result)
                        this.show = false
                        // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
                        this.$router.push({ path: '/e-commerce/questionnaire' })
                        this.$toast({
                          component: ToastificationContent,
                          position: 'bottom-right',
                          props: {
                            title: this.$t('Success'),
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: this.$t('Success'),
                          },
                        })
                      }).catch(error => {
                        console.log('fetchUsers Error : ', error)
                        this.show = false

                        this.$toast({
                          component: ToastificationContent,
                          position: 'bottom-right',
                          props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                          },
                        })
                      })
                  } else {
                    this.show = true

                    store
                      .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                      .then(result => {
                        console.log('fetch Success : ', result)
                        this.show = false

                        // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
                        this.$router.push({ path: '/e-commerce/questionnaire' })

                        this.$toast({
                          component: ToastificationContent,
                          position: 'bottom-right',
                          props: {
                            title: this.$t('Success'),
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: this.$t('Success'),
                          },
                        })
                      }).catch(error => {
                        console.log('fetchUsers Error : ', error)
                        this.show = false

                        this.$toast({
                          component: ToastificationContent,
                          position: 'bottom-right',
                          props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                          },
                        })
                      })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t('Error Validator'),
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t('Error Validator'),
                  },
                })
              }
            })
          }
        })
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to cancel')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
            this.$router.push({ path: '/eCommerce/questionnaire' })
          }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
